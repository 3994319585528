import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { getStrategyFormUrl } from '../../utils/getFormUrl';

import PopUp from '../pop-up/pop-up';

import { form, header, statusMessage } from './home-cat-modal.module.scss';
import classnames from 'classnames';

const HomeModal = ({ isOpen, handleClose, title, ...props }) => {
  const data = useStaticQuery(graphql`
    {
      wp {
        themeGeneralSettings {
          options {
            subscribeCheckbox
          }
        }
      }
    }
  `);
  const { subscribeCheckbox } = data.wp.themeGeneralSettings.options;

  const formUrl = getStrategyFormUrl;

  const [formSending, setFormSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [formMessage, setFormMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    setIsFinished(false);
    setSuccess(false);
    setFormSending(true);
    setFormMessage('Sending...');
    setShowMessage(true);

    axios
      .post(
        formUrl,
        { ...data },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: process.env.FORM_AUTH,
          },
        }
      )
      .then((response) => {
        const { success, message } = response.data;
        setFormMessage(message);
        setFormSending(false);
        setSuccess(success);
        setIsFinished(true);
        reset();
      })
      .catch((error) => {
        setFormSending(false);
        setSuccess(false);
        setIsFinished(true);
        setFormMessage(
          `We are repairing this form. Please send an&nbsp;email: <a href="mailto:patrick.jonsson@digiprocure.ai">Patrick.jonsson@digiprocure.ai</a>`
        );
        console.error(error);
      });
  };

  const catPlaceholder =
    'How high is the Supply Power/ Market complexity within the category?';

  return (
    <PopUp isOpen={isOpen} handleCloseModal={handleClose}>
      <form className={form} onSubmit={handleSubmit(onSubmit)}>
        <div className={header}>{title}</div>
        <input type={'hidden'} value={title} {...register('theme')} />
        <div>
          <input
            type="text"
            placeholder={'Category name*'}
            {...register('category', {
              minLength: {
                value: 3,
                message: 'Minimum 3 letters',
              },
              maxLength: {
                value: 36,
                message: 'Maximum 24 letters',
              },
              required: 'Category is required',
            })}
          />
          {errors?.category && (
            <span className={'inputError'}>{errors.category.message}</span>
          )}
        </div>
        <div>
          <select
            {...register('supplyPower', {
              required: 'Required',
            })}
          >
            <option selected disabled={true} value={''}>
              {catPlaceholder}
            </option>
            <option value="8">8 - Extremely high</option>
            <option value="7">7 - High</option>
            <option value="6">6 - Quite high</option>
            <option value="5">5 - More than moderate</option>
            <option value="4">4 - Moderate</option>
            <option value="3">3 - Quite Low</option>
            <option value="2">2 - Low</option>
            <option value="1">1 - Extremely low</option>
          </select>
          {errors?.supplyPower && (
            <span className={'inputError'}>{errors.supplyPower.message}</span>
          )}
        </div>
        <div>
          <select
            {...register('demandPower', {
              required: 'Required',
            })}
          >
            <option selected={true} disabled={true} value={''}>
              How high is the Demand Power/ Value impact within the category?
            </option>
            <option value="8">8 - Extremely high</option>
            <option value="7">7 - High</option>
            <option value="6">6 - Quite high</option>
            <option value="5">5 - More than moderate</option>
            <option value="4">4 - Moderate</option>
            <option value="3">3 - Quite Low</option>
            <option value="2">2 - Low</option>
            <option value="1">1 - Extremely low</option>
          </select>
          {errors?.demandPower && (
            <span className={'inputError'}>{errors.demandPower.message}</span>
          )}
        </div>
        <div>
          <input
            type="text"
            placeholder={'Your name*'}
            {...register('name', {
              minLength: {
                value: 3,
                message: 'Minimum 3 letters',
              },
              maxLength: {
                value: 24,
                message: 'Maximum 24 letters',
              },
              pattern: {
                value: /[a-zA-Z(')]+$/gm,
                message: 'Allowed only letters',
              },
              required: 'Name is required',
            })}
          />
          {errors?.name && (
            <span className={'inputError'}>{errors.name.message}</span>
          )}
        </div>
        <div>
          <input
            type="email"
            placeholder={'Your email*'}
            {...register('email', {
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
              required: 'Email is required',
            })}
          />
          {errors?.email && (
            <span className={'inputError'}>{errors.email.message}</span>
          )}
        </div>
        <div>
          <input
            type="tel"
            placeholder={'Phone number'}
            {...register('phone', {
              pattern: {
                value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                message: 'Invalid phone number',
              },
            })}
          />
          {errors?.phone && (
            <span className={'inputError'}>{errors.phone.message}</span>
          )}
        </div>
        {subscribeCheckbox && (
          <div>
            <label className={'css-checkbox'} tabIndex={0}>
              <input type="checkbox" {...register('subscribe')} />
              <span></span>
              <div>{subscribeCheckbox}</div>
            </label>
          </div>
        )}
        <div>
          <button
            type={'submit'}
            className={'button secondary expanded'}
            disabled={formSending}
          >
            Submit
          </button>
        </div>
        {showMessage && (
          <div
            dangerouslySetInnerHTML={{ __html: formMessage }}
            className={classnames(
              isFinished && success && 'success',
              isFinished && !success && 'fail',
              statusMessage
            )}
          />
        )}
      </form>
    </PopUp>
  );
};

export default HomeModal;
