import React, { useState } from 'react';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller } from 'swiper';
import 'swiper/css';

import TeamCard from '../team-card/team-card';

import Prev from '../../images/arrow-left.inline.svg';
import Next from '../../images/arrow-right.inline.svg';
import {
  wrap,
  container,
  head,
  nav,
  navButton,
} from './home-advisory.module.scss';

const HomeAdvisory = ({ title, advisors, ...props }) => {
  const [slider, setSlider] = useState(null);
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);

  return (
    <>
      {advisors && (
        <div className={classnames('section', wrap)} {...props}>
          <div className={classnames('container', container)}>
            <div className={head}>
              {title && <h2 className={'sectionTitle no-margin'}>{title}</h2>}
              <div className={nav}>
                <button
                  className={navButton}
                  onClick={() => {
                    slider.slidePrev();
                  }}
                  disabled={isFirstSlide}
                >
                  <Prev />
                </button>
                <button
                  className={navButton}
                  onClick={() => {
                    slider.slideNext();
                  }}
                  disabled={isLastSlide}
                >
                  <Next />
                </button>
              </div>
            </div>
            <div>
              <Swiper
                modules={[Controller]}
                onSwiper={setSlider}
                onSlideChange={(sl) => {
                  setIsFirstSlide(sl.isBeginning);
                  setIsLastSlide(sl.isEnd);
                }}
                spaceBetween={40}
                slidesPerView={1}
                breakpoints={{
                  1024: {
                    slidesPerView: 2,
                  },
                }}
              >
                {advisors.map((advisor, idx) => {
                  return (
                    <SwiperSlide key={idx}>
                      <TeamCard {...advisor} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeAdvisory;
