import React from 'react';
import RichText from '../rich-text/rich-text';

import Facebook from '../../images/facebook.inline.svg';
import Linkedin from '../../images/linkedin.inline.svg';

import {
  wrap,
  head,
  image,
  meta,
  metaName,
  metaPosition,
  socialList,
} from './team-card.module.scss';

const TeamCard = ({ name, position, text, img, social, ...props }) => {
  const { facebook, linkedin } = social;

  return (
    <div className={wrap}>
      <div className={head}>
        <div className={image}>
          <img src={img.sourceUrl} alt={img.altText} />
        </div>
        <div className={meta}>
          <div className={metaName}>{name}</div>
          <div className={metaPosition}>{position}</div>
          {(facebook || linkedin) && (
            <ul className={socialList}>
              {facebook && (
                <li>
                  <a href={facebook} target={'_blank'} rel={'noreferrer'}>
                    <Facebook />
                  </a>
                </li>
              )}
              {linkedin && (
                <li>
                  <a href={linkedin} target={'_blank'} rel={'noreferrer'}>
                    <Linkedin />
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
      <RichText>{text}</RichText>
    </div>
  );
};

export default TeamCard;
