import React from 'react';
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';

import youtubeParser from '../../utils/youtube-parser';

import CloseIcon from '../../images/close.inline.svg';
import {
  content,
  overlay,
  portal,
  closeButton,
  wrap,
} from './video-modal.module.scss';

const VideoModal = ({ video, isOpen, closeHandler, ...props }) => {
  const modalStyle = {
    overlay: {
      backgroundColor: null,
    },
    content: {
      top: null,
      left: null,
      right: null,
      bottom: null,
      border: null,
      background: null,
      borderRadius: null,
      padding: null,
      position: null,
      overflow: null,
    },
  };

  const videoID = youtubeParser(video);

  const initPlyr = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('.js-player')).map((p) => {
        return new Plyr(p);
      });
    }
  };

  return (
    <CSSTransition in={isOpen} timeout={300} classNames={'dialog'}>
      <Modal
        isOpen={isOpen}
        style={modalStyle}
        onRequestClose={closeHandler}
        ariaHideApp={false}
        closeTimeoutMS={500}
        overlayClassName={overlay}
        portalClassName={portal}
        className={content}
        onAfterOpen={initPlyr}
        {...props}
      >
        <div className={wrap}>
          {video && (
            <div
              className="js-player"
              data-plyr-provider="youtube"
              data-plyr-embed-id={videoID}
            />
          )}
          <button className={closeButton} onClick={closeHandler}>
            <CloseIcon />
          </button>
        </div>
      </Modal>
    </CSSTransition>
  );
};

export default VideoModal;
