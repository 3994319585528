import React from 'react';

import RichText from '../rich-text/rich-text';

import {
  card,
  head,
  icon,
  title as titleClass,
} from './service-card.module.scss';

const ServiceCard = ({ title, text, img, ...props }) => {
  const { sourceUrl, altText, mediaDetails } = img;

  return (
    <div className={card} {...props}>
      <div className={head}>
        <div className={icon}>
          <img
            src={sourceUrl}
            alt={altText}
            width={mediaDetails.width}
            height={mediaDetails.height}
          />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: title }}
          className={titleClass}
        />
      </div>
      <RichText>{text}</RichText>
    </div>
  );
};

export default ServiceCard;
