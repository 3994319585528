import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import getFormUrl from '../../utils/getFormUrl';

import Bg1 from '../../images/circles.inline.svg';
import Bg2 from '../../images/treangle2.inline.svg';
import Bg3 from '../../images/treangle3.inline.svg';
import Bg4 from '../../images/x.inline.svg';
import Bg5 from '../../images/o.inline.svg';
import Bg6 from '../../images/treangle4.inline.svg';

import {
  wrap,
  formCss,
  formGrid,
  footer,
  statusMessage,
  bg,
  bg1,
  bg2,
  bg4,
  bg5,
  bg6,
} from './home-contact.module.scss';

const HomeContact = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        themeGeneralSettings {
          options {
            subscribeCheckbox
          }
        }
      }
    }
  `);
  const { subscribeCheckbox } = data.wp.themeGeneralSettings.options;

  const formUrl = getFormUrl;

  const [formSending, setFormSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [formMessage, setFormMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    setIsFinished(false);
    setSuccess(false);
    setFormSending(true);
    setFormMessage('Sending...');
    setShowMessage(true);

    axios
      .post(
        formUrl,
        { ...data },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: process.env.FORM_AUTH,
          },
        }
      )
      .then((response) => {
        const { success, message } = response.data;
        setFormMessage(message);
        setFormSending(false);
        setSuccess(success);
        setIsFinished(true);
        reset();
      })
      .catch((error) => {
        setFormSending(false);
        setSuccess(false);
        setIsFinished(true);
        setFormMessage(
          `We are repairing this form. Please send an&nbsp;email: <a href="mailto:patrick.jonsson@digiprocure.ai">Patrick.jonsson@digiprocure.ai</a>`
        );
        console.error(error);
      });
  };

  return (
    <div className={classnames('section', wrap)} id={'contacts'}>
      <div className={'container'}>
        <h2 className={'sectionTitle center'}>Contact us</h2>
        <form className={formCss} onSubmit={handleSubmit(onSubmit)}>
          <input type={'hidden'} value={'Contact us'} {...register('theme')} />
          <div className={formGrid}>
            <div>
              <input
                type="text"
                placeholder={'Your name*'}
                {...register('name', {
                  minLength: {
                    value: 3,
                    message: 'Minimum 3 letters',
                  },
                  maxLength: {
                    value: 24,
                    message: 'Maximum 24 letters',
                  },
                  pattern: {
                    value: /[a-zA-Z(')]+$/gm,
                    message: 'Allowed only letters',
                  },
                  required: 'Name is required',
                })}
              />
              {errors?.name && (
                <span className={'inputError'}>{errors.name.message}</span>
              )}
            </div>
            <div>
              <input
                type="email"
                placeholder={'Your email*'}
                {...register('email', {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                  required: 'Email is required',
                })}
              />
              {errors?.email && (
                <span className={'inputError'}>{errors.email.message}</span>
              )}
            </div>
            <div>
              <input
                type="tel"
                placeholder={'Phone number'}
                {...register('phone', {
                  pattern: {
                    value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                    message: 'Invalid phone number',
                  },
                })}
              />
              {errors?.phone && (
                <span className={'inputError'}>{errors.phone.message}</span>
              )}
            </div>
            <div>
              <input
                type="text"
                placeholder={'Company name'}
                {...register('company', {
                  minLength: {
                    value: 3,
                    message: 'Minimum 3 letters',
                  },
                  maxLength: {
                    value: 24,
                    message: 'Maximum 24 letters',
                  },
                })}
              />
              {errors?.company && (
                <span className={'inputError'}>{errors.company.message}</span>
              )}
            </div>
          </div>
          <div>
            <textarea
              placeholder={'Message'}
              rows={4}
              {...register('message')}
            />
          </div>
          {subscribeCheckbox && (
            <div>
              <label className={'css-checkbox'} tabIndex={0}>
                <input type="checkbox" {...register('subscribe')} />
                <span></span>
                <div>{subscribeCheckbox}</div>
              </label>
            </div>
          )}
          <div className={footer}>
            <div>
              <button
                type={'submit'}
                className={classnames('button secondary')}
                disabled={formSending}
              >
                Send message
              </button>
            </div>
            {showMessage && (
              <div
                dangerouslySetInnerHTML={{ __html: formMessage }}
                className={classnames(
                  isFinished && success && 'success',
                  isFinished && !success && 'fail',
                  statusMessage
                )}
              />
            )}
          </div>
        </form>
        <Bg1 className={classnames(bg, bg1)} />
        <div className={classnames(bg, bg2)}>
          <Bg2 />
          <Bg3 />
        </div>
        <Bg4 className={classnames(bg, bg4)} />
        <Bg5 className={classnames(bg, bg5)} />
        <Bg6 className={classnames(bg, bg6)} />
      </div>
    </div>
  );
};

export default HomeContact;
