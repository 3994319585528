import React, { useState } from 'react';
import classnames from 'classnames';

import HomeModal from '../home-modal/home-modal';
import VideoModal from '../video-modal/video-modal';
import RichText from '../rich-text/rich-text';
import Breakpoint from '../breakpoint/breakpoint';

import illustration from '../../images/home-hero.svg';
import Play from '../../images/play.inline.svg';
import Circle from '../../images/hero-circle.inline.svg';

import {
  wrap,
  container,
  desc,
  buttons,
  img,
  imgWrap,
  circle,
} from './home-hero.module.scss';

const HomeHero = ({
  title,
  text,
  button,
  videoButton,
  className,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showVideo, setShowVideo] = useState(false);

  const Illustration = () => {
    return (
      <div className={imgWrap}>
        <img
          src={illustration}
          alt={'Illustration'}
          width={881}
          height={488}
          className={img}
        />
      </div>
    );
  };

  return (
    <>
      <div className={classnames(wrap, className)} {...props}>
        <div className={classnames('container', container)}>
          <div className={desc}>
            {title && <h1>{title}</h1>}
            {text && <RichText className={'no-last-margin'}>{text}</RichText>}
            <div className={buttons}>
              {button && (
                <button
                  className={'button secondary'}
                  onClick={handleOpenModal}
                >
                  {button}
                </button>
              )}
              {videoButton.title && videoButton.youtubeUrl && (
                <>
                  <button
                    className={'play-button'}
                    onClick={() => {
                      setShowVideo(true);
                    }}
                  >
                    <Play /> {videoButton.title}
                  </button>
                  <VideoModal
                    video={videoButton.youtubeUrl}
                    isOpen={showVideo}
                    closeHandler={() => {
                      setShowVideo(false);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <Breakpoint showFor={'large'}>
            <div>
              <Illustration />
            </div>
          </Breakpoint>
          <Circle className={circle} />
        </div>
        <Breakpoint showFor={'medium down'}>
          <Illustration />
        </Breakpoint>
      </div>
      <HomeModal
        isOpen={showModal}
        handleClose={handleCloseModal}
        title={button || 'Get start'}
      />
    </>
  );
};

export default HomeHero;
