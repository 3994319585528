import React from 'react';
import classnames from 'classnames';

import { wrap, container, list } from './home-customers.module.scss';

const HomeCustomers = ({ title, logos, className, ...props }) => {
  return (
    <div className={classnames(wrap, className)}>
      <div className={classnames('container', container)}>
        <div>{title}</div>
        {logos && (
          <ul className={list}>
            {logos.map((img, idx) => {
              const { sourceUrl, altText, mediaDetails } = img;
              return (
                <li key={idx}>
                  <img
                    src={sourceUrl}
                    alt={altText}
                    width={mediaDetails.width}
                    height={mediaDetails.height}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default HomeCustomers;
