import React, { useState } from 'react';
import classnames from 'classnames';

import HomeModal from '../home-modal/home-modal';

import { wrap, title } from './q-banner.module.scss';

const QBanner = ({ text, button, className, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className={classnames(className, wrap)} {...props}>
        <div className={title}>{text}</div>
        <div>
          <button className={'button secondary'} onClick={handleOpenModal}>
            {button}
          </button>
        </div>
      </div>
      <HomeModal
        isOpen={showModal}
        handleClose={handleCloseModal}
        title={button || 'Contact a specialist'}
      />
    </>
  );
};

export default QBanner;
