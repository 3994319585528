import React from 'react';
import classnames from 'classnames';
import QBanner from '../q-banner/q-banner';

import Icon from '../../images/question.inline.svg';
import {
  wrap,
  container,
  head,
  info,
  infoList,
  card,
  icon,
} from './home-questions.module.scss';

const HomeQuestions = ({ title, text, list, banner, ...props }) => {
  return (
    <div className={classnames('section', wrap)} {...props}>
      <div className={classnames('container', container)}>
        <div className={head}>
          {title && <h2 className={'sectionTitle no-margin'}>{title}</h2>}
          <div dangerouslySetInnerHTML={{ __html: text }} className={info} />
        </div>
        {list && (
          <ul className={infoList}>
            {list.map((item, idx) => {
              const { img } = item;
              return (
                <li key={idx} className={card}>
                  <div>
                    <img
                      src={img.sourceUrl}
                      alt={img.altText}
                      width={img.mediaDetails.width}
                      height={img.mediaDetails.height}
                    />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item.text }} />
                </li>
              );
            })}
          </ul>
        )}
        {banner && <QBanner {...banner} />}
        <Icon className={icon} />
      </div>
    </div>
  );
};

export default HomeQuestions;
