import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import HomeHero from '../components/home-hero/home-hero';
import HomeCustomers from '../components/home-customers/home-customers';
import HomeServices from '../components/home-servicves/home-servicves';
import HomeQuestions from '../components/home-questions/home-questions';
import HomeBenefits from '../components/home-benefits/home-benefits';
import HomeWhitePaper from '../components/home-white-paper/home-white-paper';
import HomeWho from '../components/home-who/home-who';
import HomeAdvisory from '../components/home-advisory/home-advisory';
import HomeTestimonials from '../components/home-testimonials/home-testimonials';
import HomeContact from '../components/home-contact/home-contact';

// markup
function IndexPage() {
  const data = useStaticQuery(graphql`
    {
      wp {
        page(id: "/", idType: URI) {
          frontPage {
            hero {
              title
              text
              button
              videoButton {
                title
                youtubeUrl
              }
            }
            customers {
              title
              logos {
                sourceUrl
                altText
                mediaDetails {
                  width
                  height
                }
              }
            }
            questions {
              title
              text
              list {
                img {
                  sourceUrl
                  altText
                  mediaDetails {
                    width
                    height
                  }
                }
                text
              }
            }
            banner {
              text
              button
            }
            benefits {
              title
              list {
                img {
                  sourceUrl
                  altText
                  mediaDetails {
                    width
                    height
                  }
                }
                text
              }
            }
            whitePaper {
              actionButton
              text
              video
              downloadButton
              downloadPopup {
                description
                title
              }
            }
            services {
              title
              cards {
                title
                text
                img {
                  sourceUrl
                  altText
                  mediaDetails {
                    width
                    height
                  }
                }
              }
              button
            }
            who {
              title
              text
              button
              team {
                name
                position
                text
                img {
                  sourceUrl
                  altText
                  srcSet
                  mediaDetails {
                    width
                    height
                  }
                }
                social {
                  facebook
                  linkedin
                }
              }
              partner {
                img {
                  sourceUrl
                  altText
                  srcSet
                  mediaDetails {
                    width
                    height
                  }
                }
                text
              }
            }
            advisory {
              title
              advisors {
                name
                position
                text
                img {
                  sourceUrl
                  altText
                  srcSet
                  mediaDetails {
                    width
                    height
                  }
                }
                social {
                  facebook
                  linkedin
                }
              }
            }
            testimonials {
              title
              testimonialsList {
                name
                position
                text
                img {
                  sourceUrl(size: THUMBNAIL)
                  altText
                }
              }
            }
          }
          seo {
            title
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            opengraphAuthor
            opengraphDescription
            opengraphImage {
              sourceUrl
              mediaDetails {
                width
                height
              }
              mimeType
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
            readingTime
            twitterDescription
            twitterImage {
              sourceUrl
              mediaDetails {
                width
                height
              }
              mimeType
            }
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
        }
      }
    }
  `);

  const {
    hero,
    customers,
    services,
    questions,
    banner,
    benefits,
    whitePaper,
    who,
    advisory,
    testimonials,
  } = data.wp.page.frontPage;

  const { seo } = data.wp.page;

  return (
    <>
      <Seo {...seo} />
      <Layout landingNav>
        <HomeHero {...hero} />
        <HomeCustomers {...customers} />
        <HomeQuestions {...questions} banner={banner} />
        <HomeBenefits {...benefits} />
        <HomeWhitePaper {...whitePaper} />
        <HomeServices {...services} />
        <div className={'separator'}></div>
        <HomeWho {...who} />
        <HomeAdvisory {...advisory} />
        <HomeContact />
      </Layout>
    </>
  );
}

export default IndexPage;
