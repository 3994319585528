import React from 'react';
import classnames from 'classnames';
import { Link as ScrollLink } from 'react-scroll';

import RichText from '../rich-text/rich-text';
import TeamCard from '../team-card/team-card';

import BgIcon from '../../images/treangle.inline.svg';
import {
  container,
  content,
  desc,
  grid,
  bg,
  partner as partnerCss,
  partnerLogo,
} from './home-who.module.scss';

const HomeWho = ({ title, text, button, team, partner, ...props }) => {
  return (
    <div id={'about'} className={'section'} {...props}>
      <div className={classnames('container', container)}>
        <div className={content}>
          {title && <h2 className={'sectionTitle no-margin'}>{title}</h2>}
          {text && (
            <RichText className={classnames('no-last-margin', desc)}>
              {text}
            </RichText>
          )}
          {button && (
            <div>
              <ScrollLink
                to={'contacts'}
                smooth={true}
                className={'button secondary'}
              >
                {button}
              </ScrollLink>
            </div>
          )}
        </div>
        {team && (
          <div className={grid}>
            {team.map((item, idx) => {
              return <TeamCard key={idx} {...item} />;
            })}
          </div>
        )}
        {(partner.img || partner.text) && (
          <div className={partnerCss}>
            <div className={partnerLogo}>
              {partner.img && (
                <img
                  src={partner.img.sourceUrl}
                  alt={partner.img.altText}
                  width={partner.img.mediaDetails.width}
                  height={partner.img.mediaDetails.height}
                />
              )}
            </div>
            {partner.text && <RichText>{partner.text}</RichText>}
          </div>
        )}
        <BgIcon className={bg} />
      </div>
    </div>
  );
};

export default HomeWho;
