import React, { useState } from 'react';

import ServiceCard from '../service-card/service-card';
import HomeModal from '../home-modal/home-modal';

import { grid, btn, icon1, icon2 } from './home-servicves.module.scss';

import Icon1 from '../../images/treangle.inline.svg';
import Icon2 from '../../images/circles.inline.svg';

const HomeServices = ({ title, cards, button, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className={'section'} {...props} id={'services'}>
        <div className={'container'}>
          {title && <h2 className={'sectionTitle center'}>{title}</h2>}
          {cards && (
            <div className={grid}>
              {cards.map((card, idx) => {
                return <ServiceCard key={idx} {...card} />;
              })}
              <Icon1 className={icon1} />
              <Icon2 className={icon2} />
            </div>
          )}

          {button && (
            <div className={btn}>
              <button className={'button secondary'} onClick={handleOpenModal}>
                {button}
              </button>
            </div>
          )}
        </div>
      </div>
      <HomeModal
        isOpen={showModal}
        handleClose={handleCloseModal}
        title={button || 'Request a demo'}
      />
    </>
  );
};

export default HomeServices;
