import React, { useState } from 'react';
import classnames from 'classnames';

import RichText from '../rich-text/rich-text';
import HomeWhitePaperModal from '../home-white-paper-modal/home-white-paper-modal';
import HomeCatModal from '../home-cat-modal/home-cat-modal';

import youtubeParser from '../../utils/youtube-parser';

import DocIcon from '../../images/document.inline.svg';
import DotsIcon from '../../images/dots.inline.svg';
import XIcon from '../../images/x.inline.svg';
import OIcon from '../../images/o.inline.svg';
import {
  wrap,
  container,
  content,
  buttons,
  bg,
  bg1,
  bg2,
  bg3,
} from './home-white-paper.module.scss';

const HomeWhitePaper = ({
  actionButton,
  downloadButton,
  text,
  video,
  downloadPopup,
  ...props
}) => {
  const videoID = youtubeParser(video);

  const [showWPModal, setShowWPModal] = useState(false);
  const handleOpenWPModal = () => {
    setShowWPModal(true);
  };
  const handleCloseWPModal = () => {
    setShowWPModal(false);
  };

  const [showCatModal, setShowCatModal] = useState(false);
  const handleOpenCat = () => {
    setShowCatModal(true);
  };
  const handleCloseCat = () => {
    setShowCatModal(false);
  };

  return (
    <>
      <div className={classnames('section', wrap)}>
        <div className={classnames('container', container)}>
          <div>
            {video && (
              <div
                className="js-player"
                data-plyr-provider="youtube"
                data-plyr-embed-id={videoID}
              />
            )}
          </div>
          <div className={content}>
            <RichText>{text}</RichText>
            <div className={buttons}>
              {downloadButton && (
                <button
                  className={'button secondary hollow expanded'}
                  onClick={handleOpenWPModal}
                >
                  <DocIcon width={20} height={20} />
                  {downloadButton}
                </button>
              )}
              {actionButton && (
                <button
                  type={'button'}
                  className={'button secondary expanded'}
                  onClick={handleOpenCat}
                >
                  {actionButton}
                </button>
              )}
            </div>
          </div>
          <DotsIcon className={classnames(bg, bg1)} />
          <XIcon className={classnames(bg, bg2)} />
          <OIcon className={classnames(bg, bg3)} />
        </div>
      </div>
      {downloadButton && (
        <HomeWhitePaperModal
          isOpen={showWPModal}
          handleClose={handleCloseWPModal}
          title={downloadPopup.title || downloadButton}
          text={downloadPopup.description}
        />
      )}
      {actionButton && (
        <HomeCatModal
          isOpen={showCatModal}
          handleClose={handleCloseCat}
          title={actionButton}
        />
      )}
    </>
  );
};

export default HomeWhitePaper;
