import React from 'react';
import classnames from 'classnames';

import Triangle1 from '../../images/treangle2.inline.svg';
import Triangle2 from '../../images/treangle3.inline.svg';

import {
  container,
  title as theTitle,
  info,
  card,
  bg,
  bg1,
  bg2,
} from './home-benefits.module.scss';

const HomeBenefits = ({ title, list, ...props }) => {
  return (
    <div className={'section'} {...props}>
      <div className={classnames('container', container)}>
        {title && (
          <h2 className={classnames('sectionTitle center', theTitle)}>
            {title}
          </h2>
        )}
        {list && (
          <ul className={info}>
            {list.map((item, idx) => {
              const { img, text } = item;
              return (
                <li key={idx} className={card}>
                  <div>
                    <img
                      src={img.sourceUrl}
                      alt={img.altText}
                      width={img.mediaDetails.width}
                      height={img.mediaDetails.height}
                    />
                  </div>
                  <div>{text}</div>
                </li>
              );
            })}
          </ul>
        )}
        <Triangle1 className={classnames(bg, bg1)} />
        <Triangle2 className={classnames(bg, bg2)} />
      </div>
    </div>
  );
};

export default HomeBenefits;
